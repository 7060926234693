import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  apiBaseUrl = environment.apiBaseUrl;

  constructor(public httpService:HttpService,public http:HttpClient,public storageService:StorageService) { }

  candidateList(body){
    return this.httpService.post("ReferenceCheckCandidate/GetListReferenceCheckCandidates",body);
  }

  addCandidate(body){
    return this.httpService.post("ReferenceCheckCandidate/AddReferenceCheckCandidate",body);
  }

  addReferenceCheckReferenceHistory(body){
    return this.httpService.post("ReferenceCheckReferenceHistory/AddReferenceCheckReferenceHistory",body);
  }

  editReferenceCheckReferenceHistory(body){
    return this.httpService.post("ReferenceCheckReferenceHistory/EdiReferenceCheckReferenceHistory",body);
  }

  deleteReferenceCheckReferenceHistory(body){
    return this.httpService.post("ReferenceCheckReferenceHistory/DeleteReferenceCheckReferenceHistory",body);
  }

  cancelReferenceCheckRequest(body){
    return this.httpService.post("ReferenceCheckRequest/CancelReferenceCheckRequest",body);
  }

  updateCandidate(body){
    return this.httpService.post("ReferenceCheckCandidate/EditReferenceCheckCandidate",body);
  }
  singleCandidate(body){
    return this.httpService.post("ReferenceCheckCandidate/GetSingleReferenceCheckCandidateById",body);
  }

  getSingleReferenceCheckReferenceHistory(body){
    return this.httpService.post("ReferenceCheckReferenceHistory/GetSingleReferenceCheckReferenceHistory",body);
  }

  getListOfReferenceCheckRequestsByReferenceHistoryId(body){
    return this.httpService.post("ReferenceCheckRequest/GetListOfReferenceCheckRequestsByReferenceHistoryId",body);
  }
  deleteCandidate(body){
    return this.httpService.post("",body);
  }

  addReferenceCheckCandidate(body) {
    return this.http.request('POST', this.apiBaseUrl + 'ReferenceCheckCandidate/AddReferenceCheckCandidate', { body: body, 
      headers: new HttpHeaders()
      .set('Accept', 'text/plain')
      .set('Authorization','Bearer '+JSON.parse(this.storageService.getLocal('user')).token)
    });
  }

  editReferenceCheckCandidate(body) {
    return this.http.request('POST', this.apiBaseUrl + 'ReferenceCheckCandidate/EditReferenceCheckCandidate', { body: body, 
      headers: new HttpHeaders()
      .set('Accept', 'text/plain')
      .set('Authorization','Bearer '+JSON.parse(this.storageService.getLocal('user')).token)
    });
  }
}
