import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(public httpService:HttpService) { }

  profileList(body){
    return this.httpService.post("ReferenceCheckCompanyProfile/GetCompanyProfileList",body);
  }
  addProfile(body){
    return this.httpService.postfile("ReferenceCheckCompanyProfile/AddCompanyProfile",body);
  }
  viewProfile(body){
    return this.httpService.post("ReferenceCheckCompanyProfile/GetSingleCompanyProfileRecord",body);
  }
  deleteProfile(body){
    return this.httpService.post("ReferenceCheckCompanyProfile/DeleteCompanyProfile",body);
  }
  updateProfile(body){
    return this.httpService.postfile("ReferenceCheckCompanyProfile/UpdateCompanyProfile",body);
  }
}
