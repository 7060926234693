import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  constructor(public httpService:HttpService) { }

  consentList(body){
    return this.httpService.post("ReferenceCheckConsentForms/GetConsentFormsList",body);
  }
  addConsent(body){
    return this.httpService.postfile("ReferenceCheckConsentForms/AddConsentForms",body);
  }
  viewConsent(body){
    return this.httpService.post("ReferenceCheckConsentForms/GetSingleConsentFormsRecord",body);
  }
  updateConsent(body){
    return this.httpService.post("ReferenceCheckConsentForms/UpdateConsentForms",body);
  }
  deleteConsent(body){
    return this.httpService.post("ReferenceCheckConsentForms/DeleteConsentForms",body);
  }
}
