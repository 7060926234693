import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyDirectoryServicesModule } from './services/company-directory-services.module';
import { IonicModule } from '@ionic/angular';
import { CommonComponentsModule } from 'src/app/components/common/common.components.module';

const components = [
]


@NgModule({
  declarations: [components],
  exports: [components],
  imports: [
    CommonModule,CompanyDirectoryServicesModule,IonicModule,CommonComponentsModule
  ]
})
export class CompanyDirectoryModule { }
